import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import CodeInput from "./CodeInput";

const Login = () => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const { checkCompanyEmail, companyLoginWithCode } = useAuth();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser && currentUser.token) {
      navigate("/dashboard");
    }
  }, [currentUser, navigate]);

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    const isEmailValid = await checkCompanyEmail(email);
    if (isEmailValid) {
      setEmailSent(true); // Changes the form to the code input form.
    }
  };

  const handleCodeSubmit = async (code) => {
    const isAuthenticated = await companyLoginWithCode(email, code);
    if (isAuthenticated) {
      navigate("/dashboard"); // Navigate to the dashboard upon successful authentication.
    }
  };

  const handleSignUpClick = () => {
    navigate("/signup");
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 px-6 lg:px-8">
      {/* Left Section: Logo */}
      <div className="hidden lg:flex flex-1 max-w-md flex-col items-center justify-center space-y-4">
        <h1 className="text-pink-500 font-bold text-6xl">KinAlma</h1>
      </div>

      {/* Right Section: Login */}
      <div className="flex flex-1 max-w-md flex-col bg-white rounded-lg shadow-lg px-6 py-8 space-y-6">
        <div className="flex lg:hidden justify-center mb-6">
          <h1 className="text-pink-500 font-bold text-5xl">KinAlma</h1>
        </div>

        <h2 className="text-2xl font-bold text-gray-700">Sign in to your account</h2>
        <form
          onSubmit={emailSent ? handleCodeSubmit : handleEmailSubmit}
          className="space-y-6"
        >
          {!emailSent ? (
            <>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    autoComplete="email"
                    className="block w-full rounded-md bg-gray-50 px-3 py-1.5 text-gray-900 text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full rounded-md bg-indigo-600 px-4 py-2 text-white font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                >
                  Get Code
                </button>
              </div>
            </>
          ) : (
            <>
              <CodeInput numberOfDigits={6} onComplete={handleCodeSubmit} />
              <button
                type="submit"
                className="w-full rounded-md bg-indigo-600 px-4 py-2 text-white font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600"
              >
                Login
              </button>
            </>
          )}
        </form>

        <p className="text-center text-sm text-gray-500">
          Not a member?{" "}
          <span
            onClick={handleSignUpClick}
            className="font-medium text-indigo-600 hover:text-indigo-700 cursor-pointer"
          >
            Sign up
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;
