import React from "react";
import SignUpForm from "./SignUpForm";

export default function SignUpPage() {
  return (
    <div className="min-h-screen flex items-center justify-center p-4 bg-gray-50">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md">
        <div className="pt-6 p-4">
            <div className="px-4 space-y-2">
              <h1 className="text-3xl font-semibold tracking-tight">
                Sign up your Funeral Home
              </h1>
              <p className="text-gray-600">
                Enter your company details below to create an account
              </p>
            </div>
            <SignUpForm />
        </div>
      </div>
    </div>
  );
}