import React, { useRef, useEffect, useState } from 'react';

function CodeInput({ numberOfDigits, onComplete }) {
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const otpBoxReference = useRef([]);

  useEffect(() => {
    // Check if all digits are filled and if so, call onComplete
    if (otp.every(digit => digit.trim() !== "")) {
      onComplete(otp.join(""));
    }
  }, [otp, onComplete]); 

  function handleChange(value, index) {
    if (/^\d*$/.test(value)) {
      let newArr = [...otp];
      newArr[index] = value;
      setOtp(newArr);

      if (value && index < numberOfDigits - 1) {
        otpBoxReference.current[index + 1].focus();
      }
    }
  }

  function handlePaste(e) {
    e.preventDefault();
    const pastedData = e.clipboardData
      .getData("text")
      .replace(/\D/g, "")
      .slice(0, numberOfDigits);
    const newOtp = [
      ...pastedData.split(""),
      ...Array(numberOfDigits - pastedData.length).fill(""),
    ];
    setOtp(newOtp);

    const nextEmptyIndex = newOtp.findIndex((digit) => digit === "");
    const focusIndex = nextEmptyIndex === -1 ? numberOfDigits - 1 : nextEmptyIndex;
    otpBoxReference.current[focusIndex]?.focus();
  }

  function handleKeyDown(e, index) {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
  }

  return (     
    <div className='flex flex-row flex-nowrap items-center justify-around space-x-4'>
      {otp.map((digit, index) => (
        <input
          key={index}
          value={digit}
          maxLength={1}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          ref={(reference) => (otpBoxReference.current[index] = reference)}
          className="w-11 h-11 text-center text-2xl rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 bg-gray-200"
        />
      ))}
    </div>
  );
}

export default CodeInput;