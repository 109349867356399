import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask"; 
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import CodeInput from "./CodeInput";

export default function SignUpForm() {
  const { signUp, companyLoginWithCode } = useAuth();
  const { currentUser } = useAuth();
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    companyName: "",
    email: "",
    phone: "",
    address: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    },
  });
  
  const [emailError, setEmailError] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  

  // Redirect to dashboard if user is already authenticated
  useEffect(() => {
    if (currentUser && currentUser.token) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  // Real-time form validation
  useEffect(() => {
    const validEmail = formData.email.includes('@');
    const validCompanyName = formData.companyName.trim() !== '';
    const validAddress = formData.address.address1.trim() !== '' &&
                         formData.address.city.trim() !== '' &&
                         formData.address.state.trim() !== '' &&
                         formData.address.zip.trim() !== '';
    const validPhone = formData.phone.replace(/\D/g, '').length >= 10;


  }, [formData]);

  // Function to validate inputs and set error messages
  const validateInput = () => {
    let isValid = true;

    // Validate Email
    if (!formData.email.includes('@')) {
      setEmailError('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError('');
    }

    // Validate Company Name
    if (formData.companyName.trim() === '') {
      setCompanyNameError('Company name is required.');
      isValid = false;
    } else {
      setCompanyNameError('');
    }

    // Validate Address Fields (excluding address2)
    if (!formData.address.address1.trim() || !formData.address.city.trim() || 
        !formData.address.state.trim() || !formData.address.zip.trim()) {
      setAddressError('All address fields (except Address 2) are required.');
      isValid = false;
    } else {
      setAddressError('');
    }

    // Validate Phone Number
    if (formData.phone.replace(/\D/g, '').length < 10) {
      setPhoneError('Please enter a valid phone number with at least 10 digits.');
      isValid = false;
    } else {
      setPhoneError('');
    }

    return isValid;
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('address.')) {
      const addressField = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        address: {
          ...prev.address,
          [addressField]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  // Handle form submission for sign up
  const handleSignUp = async (event) => {
    event.preventDefault();
    // Clear previous errors
    setEmailError('');
    setCompanyNameError('');
    setAddressError('');
    setPhoneError('');

    if (validateInput()) { 
      const submissionData = {
        ...formData,
        phone: formData.phone.replace(/\D/g, ''),
      };
      const isSignUpValid = await signUp(submissionData.companyName, submissionData.address, submissionData.email, submissionData.phoneNumber);
      if (isSignUpValid) {
        setEmailSent(true);
      }
    }
  };

  // Handle code submission for login
  const handleCodeSubmit = async (code) => { // Adjusted parameter to accept code directly
    const isAuthenticated = await companyLoginWithCode(formData.email, code);
    if (isAuthenticated) {
      navigate('/dashboard'); // Navigate to the dashboard upon successful authentication.
    }
  };

  const states = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", 
    "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", 
    "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", 
    "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", 
    "WI", "WY"
  ];

  return (
    <form onSubmit={emailSent ? handleCodeSubmit : handleSignUp} className="space-y-4">
      {!emailSent ? (
        <>
          <div className="space-y-0">
            <div className="border-b border-gray-900/10 pb-5">
              <div className="mt-4 mx-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6">
                {/* Company Name */}
                <div className="sm:col-span-full">
                  <label htmlFor="companyName" className="block text-sm font-medium text-gray-900">
                    Company name
                  </label>
                  <div className="mt-1">
                    <input
                      id="companyName"
                      name="companyName"
                      autoComplete="organization"
                      value={formData.companyName}
                      onChange={handleChange}
                      className="block w-full rounded-md bg-white px-3 py-1 text-base text-gray-900 outline outline-1 outline-gray-300 placeholder:text-gray-400 focus:outline-indigo-600 sm:text-base"
                    />
                    {companyNameError && (
                      <p className="mt-1 text-sm text-red-600">{companyNameError}</p>
                    )}
                  </div>
                </div>

                {/* Company Email */}
                <div className="sm:col-span-full">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-900">
                    Company Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      autoComplete="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="block w-full rounded-md bg-white px-3 py-1 text-base text-gray-900 outline outline-1 outline-gray-300 placeholder:text-gray-400 focus:outline-indigo-600 sm:text-base"
                    />
                    {emailError && (
                      <p className="mt-1 text-sm text-red-600">{emailError}</p>
                    )}
                  </div>
                </div>

                {/* Phone Number */}
                <div className="sm:col-span-full">
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-900">
                    Company Phone Number
                  </label>
                  <div className="mt-1">
                    <InputMask
                      mask="(999) 999-9999" // Define the mask pattern here
                      maskChar=" " // Optional: character to display for unfilled parts
                      id="phone"
                      name="phone"
                      autoComplete="tel"
                      value={formData.phone}
                      onChange={handleChange}
                    >
                      {(inputProps) => (
                        <input
                          {...inputProps}
                          className="block w-full rounded-md bg-white px-3 py-1 text-base text-gray-900 outline outline-1 outline-gray-300 placeholder:text-gray-400 focus:outline-indigo-600 sm:text-base"
                        />
                      )}
                    </InputMask>
                    {phoneError && (
                      <p className="mt-1 text-sm text-red-600">{phoneError}</p>
                    )}
                  </div>
                </div>

                {/* Address Fields */}
                <div className="col-span-full">
                  <label htmlFor="address1" className="block text-sm font-medium text-gray-900">
                    Street Address 1
                  </label>
                  <div className="mt-1">
                    <input
                      id="address1"
                      name="address.address1"
                      autoComplete="street-address"
                      value={formData.address.address1}
                      onChange={handleChange}
                      className="block w-full rounded-md bg-white px-3 py-1 text-base text-gray-900 outline outline-1 outline-gray-300 placeholder:text-gray-400 focus:outline-indigo-600 sm:text-base"
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label htmlFor="address2" className="block text-sm font-medium text-gray-900">
                    Apartment, suite, etc. (optional)
                  </label>
                  <div className="mt-1">
                    <input
                      id="address2"
                      name="address.address2"
                      autoComplete="address-line2"
                      value={formData.address.address2}
                      onChange={handleChange}
                      className="block w-full rounded-md bg-white px-3 py-1 text-base text-gray-900 outline outline-1 outline-gray-300 placeholder:text-gray-400 focus:outline-indigo-600 sm:text-base"
                    />
                  </div>
                </div>

                {/* Country */}
                <div className="sm:col-span-3">
                  <label htmlFor="country" className="block text-sm font-medium text-gray-900">
                    Country
                  </label>
                  <div className="mt-1 grid grid-cols-1">
                    <select
                      id="country"
                      name="address.country"
                      autoComplete="country-name"
                      value={formData.address.country}
                      onChange={handleChange}
                      className="w-full appearance-none rounded-md bg-white py-1 pl-3 pr-8 text-base text-gray-900 outline outline-1 outline-gray-300 focus:outline-indigo-600 sm:text-base"
                    >
                      <option value="">Select Country</option>
                      <option value="United States">United States</option>
                      <option value="Canada">Canada</option>
                      <option value="Mexico">Mexico</option>
                    </select>
                  </div>
                </div>

                {/* State */}
                <div className="sm:col-span-3">
                  <label htmlFor="state" className="block text-sm font-medium text-gray-900">
                    State
                  </label>
                  <div className="mt-1 grid grid-cols-1">
                    <select
                      id="state"
                      name="address.state"
                      autoComplete="address-level1"
                      value={formData.address.state}
                      onChange={handleChange}
                      className="w-full appearance-none rounded-md bg-white py-1 pl-3 pr-8 text-base text-gray-900 outline outline-1 outline-gray-300 focus:outline-indigo-600 sm:text-base"
                    >
                      <option value="">Select State</option>
                      {states.map((state) => (
                        <option key={state} value={state}>{state}</option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* City */}
                <div className="sm:col-span-3">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-900">
                    City
                  </label>
                  <div className="mt-1">
                    <input
                      id="city"
                      name="address.city"
                      autoComplete="address-level2"
                      value={formData.address.city}
                      onChange={handleChange}
                      className="block w-full rounded-md bg-white px-3 py-1 text-base text-gray-900 outline outline-1 outline-gray-300 placeholder:text-gray-400 focus:outline-indigo-600 sm:text-base"
                    />
                  </div>
                </div>

                {/* Zip */}
                <div className="sm:col-span-3">
                  <label htmlFor="zip" className="block text-sm font-medium text-gray-900">
                    Zip
                  </label>
                  <div className="mt-1">
                    <input
                      id="zip"
                      name="address.zip"
                      autoComplete="postal-code"
                      value={formData.address.zip}
                      onChange={handleChange}
                      className="block w-full rounded-md bg-white px-3 py-1 text-base text-gray-900 outline outline-1 outline-gray-300 placeholder:text-gray-400 focus:outline-indigo-600 sm:text-base"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className={"w-full px-4 py-2 text-white font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 bg-indigo-600 hover:bg-indigo-700"}
          >
            Sign Up
          </button>
        </>
      ) : (
        <>
          <CodeInput numberOfDigits={6} onComplete={handleCodeSubmit} />
          <button
            className="w-full px-4 py-2 bg-indigo-600 text-white font-medium rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            type="submit"
          >
            Login
          </button>
        </>
      )}
    </form>
  );
}
