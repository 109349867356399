import React, { useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";


const AddCardForm = ({ clientSecret, onCardAdded }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      setError("Stripe has not loaded yet. Please try again later.");
      setLoading(false);
      return;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {}, 
      redirect: 'if_required',
    });
    

    if (error) {
      setError(error.message);
      setLoading(false);
      return;
    }

    onCardAdded();
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="w-[35rem] mx-auto p-8 bg-white rounded-lg drop-shadow-lg mt-8">
      <h2 className="text-2xl font-semibold text-gray-900 mb-6">Add a Payment Method</h2>
      <div className="mb-8 p-6 bg-gray-50 rounded-md">
        <PaymentElement />
      </div>
      {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
      <button
        type="submit"
        disabled={loading}
        className="w-full px-4 py-2 bg-indigo-600 text-white font-medium rounded-md shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {loading ? "Saving..." : "Save Card"}
      </button>
    </form>
  );
};

export default AddCardForm;
