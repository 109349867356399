import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export function DashboardNav() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const handleAuthAction = () => {
    if (currentUser) {
      logout();
    } else {
      navigate("/login");
    }
  };

  return (
    <nav className="top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-sm w-full">
      <div className="container mx-auto flex items-center py-4">
        <div className="w-[150px]">
          <a href="/" className="text-primary text-pink-400 font-bold text-4xl">
            KinAlma
          </a>
        </div>
        <div className="flex-1 flex justify-center">
          <div className="hidden items-center gap-8 md:flex">
           
          </div>
        </div>
        <div className="w-[150px] flex justify-end">
          <button
            className="font-medium bg-transparent text-pink-400 cursor-pointer text-lg"
            onClick={handleAuthAction}
          >
            {currentUser ? "Log out" : "Log in"}
          </button>
        </div>
      </div>
    </nav>
  );
}