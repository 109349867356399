import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../../services/StripeProvider";
import AddCardForm from "./AddCardForm";
import { useAuth } from '../../contexts/AuthContext';
import Swal from 'sweetalert2';

const AddCardPage = () => {
    const { currentUser } = useAuth();
    const [clientSecret, setClientSecret] = useState(null);

    const appearance = {
        theme: "flat",
        variables: {
          colorPrimary: "#0570de",
          colorBackground: "#f6f9fc",
          colorText: "#30313d",
          colorDanger: "#df1b41",
        },
    };

    useEffect(() => {
        console.log("Intent getting called.")
        const fetchSetupIntent = async () => {
            const response = await fetch("https://kinalma-app-696ccda80bc9.herokuapp.com/setupIntent", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + currentUser.token,
                },
            });
            const data = await response.json();
            if (data.clientSecret) {
                setClientSecret(data.clientSecret);
            } else {
                console.error("Failed to fetch clientSecret:", data);
            }
        };

        fetchSetupIntent();
    }, [currentUser]);

    const handleCardAdded = () => {
              // Display SweetAlert on successful payment setup
      Swal.fire({
        title: 'Success!',
        text: 'Payment method added successfully!',
        icon: 'success',
        showConfirmButton: false,
        timer: 2500
      });
    };

    return clientSecret ? (
        <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
            <AddCardForm onCardAdded={handleCardAdded} />
        </Elements>
    ) : (
        <p>Loading...</p>
    );
};

export default AddCardPage;
