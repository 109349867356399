import AOS from 'aos';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/joy/Button';
import 'aos/dist/aos.css';
import { DashboardNav } from './DashboarNav';
import AddCard from './AddCard';

const Dashboard = () => {
    const { logout, getUser } = useAuth();
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init();
        const fetchUserData = async () => {
            const data = await getUser(); // Assuming getUser is asynchronous
            console.log("data: ", data);
            setUserData(data);
        };
        fetchUserData();
    }, []);

    const renderComponent = () => {
        if (!userData) {
            return <p></p>;
        }
        if (userData.companyInfo.hasSavedCreditCardInfo) {
            return (
                <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
                    <div className="text-center">
                        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                            Welcome, {userData ? userData.companyInfo.companyName : 'loading...'}!
                        </h2>
                        <p className="mt-4 text-lg text-gray-500">
                            Start adding customers to KinAlma!
                        </p>
                        <div className="mt-8">
                            <button
                                onClick={() => navigate('/dashboard/add-user')}
                                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                            >
                                Add New Customer
                            </button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <AddCard />;
        }
    };

    return (
        <div className="flex flex-col min-h-screen">
            <DashboardNav />

            {/* Welcome Section
            <div className="absolute top-[20%] left-[15%] text-4xl sm:text-5xl font-extrabold text-gray-900">
                <p data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000">
                    Welcome, {userData ? userData.companyInfo.companyName : 'loading...'}!
                </p>
            </div> */}

            {/* Main Content */}
            <div className="flex flex-grow justify-center items-center">
                <div className="text-center">
                    {renderComponent()}
                </div>
            </div>

            {/* Footer */}
            <div className="flex flex-row pb-2 justify-center text-white text-sm font-medium">
                <span>&copy; {new Date().getFullYear()} KinAlma. All rights reserved.</span>
            </div>
        </div>
    );
};

export default Dashboard;
