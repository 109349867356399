import React from "react";
import image1 from "../assets/image1.jpeg";

export function ImageGrid() {
  return (
    <div className="relative w-[700px] h-[600px]">
      {/* Top Left Image */}
      <div className="absolute top-0 left-[30%] rounded-2xl overflow-hidden shadow-lg transform translate-y-[-10%] translate-x-[-10%]">
        <img
          src={image1}
          alt="People collaborating"
          className="object-cover w-[200px] h-[300px]"
        />
      </div>

      {/* Top Right Image */}
      <div className="absolute top-10 right-0 rounded-2xl overflow-hidden shadow-lg transform translate-x-[10%]">
        <img
          src={image1}
          alt="Person working"
          className="object-cover w-[250px] h-[300px]"
        />
      </div>

      {/* Center Image */}
      <div className="absolute top-[40%] left-[30%] rounded-2xl overflow-hidden shadow-lg transform translate-y-[-10%]">
        <img
          src={image1}
          alt="Team meeting"
          className="object-cover w-[300px] h-[350px]"
        />
      </div>

      {/* Bottom Left Image */}
      <div className="absolute -bottom-10 left-[20%] rounded-2xl overflow-hidden shadow-lg transform translate-x-[-5%]">
        <img
          src={image1}
          alt="Office collaboration"
          className="object-cover w-[200px] h-[250px]"
        />
      </div>

      {/* Bottom Right Image */}
      <div className="absolute bottom-0 right-0 rounded-2xl overflow-hidden shadow-lg transform translate-x-[10%]">
        <img
          src={image1}
          alt="Remote work"
          className="object-cover w-[275px] h-[300px]"
        />
      </div>
    </div>
  );
}
