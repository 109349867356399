import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Landing from './components/page'; // Make sure to create this component
import Login from './components/Login';
import SignUpPage from './components/SignUpPage'
import Dashboard from './components/dashboard/Dashboard';
import AddUser from './components/dashboard/AddUser';
import NotFound from './components/NotFound';
import AuthGuard from './guards/auth.guard';
import './App.css'


const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signUp" element={<SignUpPage />} />
      <Route path="/dashboard" element={
        <AuthGuard>
          <Dashboard />
        </AuthGuard>
      } />
      <Route path="/dashboard/add-user" element={
        <AuthGuard>
          <AddUser />
        </AuthGuard>
      } />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
